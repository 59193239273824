import React from "react";
import {Route,NavLink,Routes,Navigate} from "react-router-dom"

import News from "../../views/News";
import Message from "../../views/Message";
export default class Home extends React.Component{
    render() {
        return (
            <div>
                <h2>嵌套路由组件</h2>
                <div>
                    <ul className="nav nav-tabs">
                        <li>
                            <NavLink className="list-group-item activeClass" to="news">News</NavLink>
                        </li>
                        <li>
                            <NavLink className="list-group-item" to="message">Message</NavLink>
                        </li>
                    </ul>
                    <div>
                        <Routes>
                            <Route path = "news" element = {<News/>}/>
                            <Route path = "message/*" element = {<Message/>}/>
                            <Route path = "*" element = {<Navigate to = "news"/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        )
    }
}
