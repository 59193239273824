import React from "react";

export default class About extends React.Component{
    render() {
        return (
            <div>
                <h1>Hello About</h1>
            </div>
        )
    }
}