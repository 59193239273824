import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom'
//import LoginForm from './components/LoginForm';
import App from "./components/Comments/app/app";
import reportWebVitals from './reportWebVitals';

const main = ReactDOM.createRoot(document.getElementById('main'));


main.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
