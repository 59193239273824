import React from "react";
import PropTypes from 'prop-types'

import CommentItem from "../comment-item/comment-item";
import "./comment-list.css"
export default class CommentList extends React.Component{
    //给组件类指定属性
    static propTypes = {
        comments:PropTypes.array.isRequired,
        deleteComment:PropTypes.func.isRequired
    }
    render() {
        const {comments,deleteComment} = this.props
        //计算是否显示
        const display = comments.length === 0 ? 'block':'none';
        return (
            <div className="col-md-8 page-header" >
                <h3 className="reply">评论回复:</h3>
                <h2 style={{display}}>暂无评论,点击左侧添加评论！！！</h2>
                <ul className="list-group">
                    {
                        comments.map((comment,index)=>
                            <CommentItem className = "itemStyle" comment={comment} key={index} index = {index} deleteComment={deleteComment}/>)
                    }
                </ul>
            </div>
        )
    }
}