import React from "react";
import PropTypes from "prop-types";

import './comment-item.css';

export default class CommentItem extends React.Component{
    static propTypes = {
        comment:PropTypes.object.isRequired,
        deleteComment:PropTypes.func.isRequired,
        index:PropTypes.number.isRequired
    }
    handleClick = () => {
        const {comment,deleteComment,index} = this.props
        //提示
        if (window.confirm(`确定删除${comment.username}的评论吗?`)){
            deleteComment(index);
        }
        //确定后删除
    }
    render() {
        const {comment} = this.props;
        return (
            <li className="list-group-item">
                <div>
                    <button className="btn btn-default pull-right" onClick={this.handleClick}>删除</button>
                </div>
                <p className="user"><span>{comment.username}</span><span>说:</span></p>
                <p className="centence">{comment.content}</p>
            </li>
        )
    }
}