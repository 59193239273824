import React from "react";

export default class MessageDetail extends React.Component{
    render() {
        return (
            <ul>
                <li>ID:6</li>
                <li>TITLE:Message006</li>
                <li>CONTENT:NULL</li>
            </ul>
        )
    }
}