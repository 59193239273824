import React from "react";
import {NavLink} from "react-router-dom"
import './my-nav-link.css'
export default class MyNavLink extends React.Component{
    render() {
        return <NavLink {...this.props} className={({isActive})=>
            ("my-group-item list-group-item " + (isActive?"my-active-class":""))}
        >
        </NavLink>

    }
}