import React from "react";
import {Route,Routes,Navigate} from "react-router-dom"

import MyNavLink from "./my-nav-link";
import Home from "../Home/home";
import About from "../About/about";

export default class Nav extends React.Component{
    render() {
        return (
            <div className="row">
                <div className="col-xs-2 col-xs-offset-1">
                    <div className="list-group">
                        <MyNavLink className = "list-group-item" to = "/about">About</MyNavLink>
                        <MyNavLink className = "list-group-item" to = "/home">Home</MyNavLink>
                    </div>
                </div>
                <div className="col-xs-6">
                    <div className="panel">
                        <div className="panel-body">
                            <Routes>
                                <Route path = "/about" element = {<About/>}></Route>
                                <Route path = "/home/*" element = {<Home/>}></Route>
                                <Route path="*" element = {<Navigate to = "/home"/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}