import React from "react";
import { Route, Routes,Link} from "react-router-dom"
import MessageDetail from "./MessageDetail";
export default class Message extends React.Component{
    state = {
        messages:[
            // {id : 1,title:"message001"},
            // {id : 3,title:"message003"},
            // {id : 5,title:"message005"}
        ]
    }
    componentDidMount() {
        //模拟发送ajax请求异步获取数据
        setTimeout(()=>{
            const messages = [
                {id : 1,title:"message001"},
                {id : 3,title:"message003"},
                {id : 5,title:"message005"}
            ];
            this.setState({messages})
        },1000);
    }

    render() {
        return (
            <div >
                <ul>
                    {
                        this.state.messages.map((m,index)=>(
                            <li key={index}>
                                <Link className="a" to={
                                    `/home/message/messagedetail/${m.id}`
                                } >{m.title}</Link>
                            </li>
                        ))
                    }
                </ul>
                <Routes>
                    <Route path = 'messagedetail/:id' element = {<MessageDetail/>}/>
                </Routes>
            </div>

        )
    }
}