import React from "react";
import PubSub from "pubsub-js"

export default class Search extends React.Component{

    search=()=>{
        const searchName = this.input.value.trim()
        if (searchName){
            PubSub.publish('search',searchName)
        }
    }
    componentDidMount() {
    }

    render() {
        return (
            <div className="col-md-12 page-header">
                <div className="form-group col-sm-4">
                    <input type="text" className="form-control" placeholder=" 名字或者标题"
                           ref = {input=>this.input = input}/>
                </div>
                <div className = "col-sm-2">
                    <button type="button" className="btn btn-default" onClick={this.search}>搜索</button>
                </div>
            </div>
        )
    }
}