import React from "react";

import CommentAdd from "../comment-add/comment-add";
import CommentList from "../comment-list/comment-list";
import SearchComment from "../../Search/search/search";
import Nav from "../../Nav/Nav";
export default class App extends React.Component{

    state = {
        comments:[
            {username:'Tom',content:'React挺好的'},
            {username:'Jack',content:'React太难了'},
        ]
    }
    addComment = (comment) => {
        const {comments} = this.state
        comments.unshift(comment)
        //更新状态
        this.setState({comments})
        console.log(JSON.stringify(comments))
    }
    //删除评论
    deleteComment = (index) => {
        let {comments} = this.state
        comments.splice(index,1)
        console.log(index)
        //更新状态
        this.setState({comments})
    }
    render() {
        return (
            <div>
                <header className="site-header jumbotron">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <h1>web3.0 DappReact的组件封装</h1>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container">
                    <SearchComment/>
                    <CommentAdd addComment = {this.addComment} />
                    <CommentList deleteComment = {this.deleteComment} comments = {this.state.comments} />
                </div>
                <Nav/>
            </div>
        )
    }
}